import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import Layout from '../../components/layout'
import { isLoggedIn, isMFauth } from '../../services/auth'
import { handleGetSandboxApplication, handleGetDelegatedApplications, handleGetProductionApplication, handleGetRequestApplication, handleGetApiDetails, handleTriggerMFA, handleMFauth } from '../../services/api'
import { navigate } from '@reach/router'
import Breadcrumb from '../../components/breadcrumb'
import { MdAdd, MdChevronRight } from 'react-icons/md'
import { Container, Button, Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, Card, Badge, CardFooter, CardBody, CardTitle, CardText, UncontrolledAlert, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"
import classnames from 'classnames'
import { BarLoader } from 'react-spinners';
import Amplify, { Auth, Cache, Hub, Logger, API } from "aws-amplify";

import '../../components/styles/custom.css'
import { Banner } from '../../components/banner';
import moment from 'moment'
import ReactGA from 'react-ga'

const user = isLoggedIn() ? JSON.parse(window.localStorage.getItem("gatsbyUser")) : false
const override = JSON.stringify({
    margin: `0 auto`
})

export default class Application extends Component {
    constructor(props) {
        super(props)

        this.state = {
            api: []
        }
    }

    async componentDidMount() {



        ReactGA.initialize(`$(process.env.GA_KEY)`);
        ReactGA.pageview('account/applications');

        if (!isLoggedIn()) {
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }
        this.forceUpdate()

    }

    UNSAFE_componentWillUpdate() {
        if (!isLoggedIn()) {
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
        }
    }

    UNSAFE_componentWillMount() {
        if (!isLoggedIn()) {
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }
        if (Cache.getItem("ma") != 1) {
            navigate(`/account/mfauth/`)
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }
        this.forceUpdate()
    }


    render() {
        return (
            <ApplicationLayout location={this.props.location}>
                <AppContent data={this.props.location} />
            </ApplicationLayout>
        )
    }
}


export class AppContent extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1',
            username: user ? user.userData.username : "",
            email: user ? user.userData.attributes.email : "",
            isLoading: false,
            data: {
                Items: []
            },
            notification: !props.data.state ? null : props.data.state.notification,
            message: !props.data.state ? null : props.data.state.message
        };
    }

    componentDidMount = async () => {


        this.setState({
            isLoading: true
        })

        let result = null
        await handleGetSandboxApplication(this.state)
            .then((res) => {
                if (res === '' || res === []) {
                    var sortedArray = []
                } else {
                    try {
                        var sortedArray = JSON.parse(res).Items.sort(this.dynamicSort("-createdTimestamp"))

                    } catch (e) {
                        if (res) {
                            var sortedArray = res.Items.sort(this.dynamicSort("-createdTimestamp"))
                        }
                    }
                }
                result = sortedArray
            })
        let del = await handleGetDelegatedApplications(this.state)
        let prod = await handleGetProductionApplication(this.state)
        let req = await handleGetRequestApplication(this.state)
        let apis = await handleGetApiDetails()




        if (!del || del === '') {
            del = [];
        } else {
            del.sort(this.dynamicSort('-createdTimestamp'))
        }

        if (prod === '' || !prod) {
            prod = []
        } else {
            prod.sort(this.dynamicSort('-createdTimestamp'))
        }

        if (req === '' || !req) {
            req = []
        } else {
            req.sort(this.dynamicSort('-requestdate'))
        }


        this.setState({
            data: !result ? '' : result,
            delegates: !del ? '' : del,
            production: !prod ? '' : prod,
            req: !req ? '' : req,
            api: apis
        }, () => {
            this.setState({
                isLoading: false
            })
        })
    }

    dynamicSort(property) {
        var sortOrder = 1
        if (property[0] === "-") {
            sortOrder = -1
            property = property.substr(1)
        }

        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
            return result * sortOrder
        }
    }

    toggleSort(mode) {

        let state = this.state

        let sbox = !state.data ? [] : state.data
        let del = !state.delegates ? [] : state.delegates
        let prod = !state.production ? [] : state.production
        let req = !state.req ? [] : state.req

        switch (mode) {
            case 'alphaA':
                this.setState({
                    data: sbox.sort(this.dynamicSort('name')),
                    delegates: del.sort(this.dynamicSort('name')),
                    production: prod.sort(this.dynamicSort('name')),
                    req: req.sort(this.dynamicSort('app_name'))
                })
                break;
            case 'alphaZ':
                this.setState({
                    data: sbox.sort(this.dynamicSort('-name')),
                    delegates: del.sort(this.dynamicSort('-name')),
                    production: prod.sort(this.dynamicSort('-name')),
                    req: req.sort(this.dynamicSort('-app_name'))
                })

                break;

            case 'dateA':

                this.setState({
                    data: sbox.sort(this.dynamicSort('-createdTimestamp')),
                    delegates: del.sort(this.dynamicSort('-createdTimestamp')),
                    production: prod.sort(this.dynamicSort('-createdTimestamp')),
                    req: req.sort(this.dynamicSort('-requestdate'))
                })

                break;
            case 'dateZ':

                this.setState({
                    data: sbox.sort(this.dynamicSort('createdTimestamp')),
                    delegates: del.sort(this.dynamicSort('createdTimestamp')),
                    production: prod.sort(this.dynamicSort('createdTimestamp')),
                    req: req.sort(this.dynamicSort('requestdate'))
                })

                break;
            default:
                break;
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }



    render() {
        return (
            <div>
                {!this.state.notification ? null :
                    (
                        <UncontrolledAlert color={this.state.notification}>
                            {this.state.message}
                        </UncontrolledAlert>
                    )}
                <Row className="clearfix">
                    <Col className="text-left">
                        <UncontrolledDropdown>
                            <DropdownToggle caret color="link">
                                Sort By
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => { this.toggleSort('alphaA') }}>Alphabetical (A-Z)</DropdownItem>
                                <DropdownItem onClick={() => { this.toggleSort('alphaZ') }}> Alphabetical (Z-A)</DropdownItem>
                                <DropdownItem onClick={() => { this.toggleSort('dateA') }}>Date Created (Latest First)</DropdownItem>
                                <DropdownItem onClick={() => { this.toggleSort('dateZ') }}>Date Created (Oldest First)</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Col>
                    <Col className="text-right">
                        <Button color="primary"
                            onClick={
                                event => {
                                    event.preventDefault()
                                    navigate('create/')
                                }
                            }>
                            <MdAdd /> New Application
                        </Button>{' '}
                    </Col>
                </Row>
                <Row>
                    {/* Nav Tabs */}
                    <Col md={12}>
                        <Nav tabs style={{ marginLeft: `0` }}>
                            <NavItem tag="a" href="#" title="Applications for Testing">
                                <NavLink
                                    tag="font"
                                    className={classnames({ active: this.state.activeTab === '1' })}
                                    onClick={() => { this.toggle('1'); }}
                                >
                                    Sandbox
                                </NavLink>
                            </NavItem>
                            <NavItem tag="a" href="#" title="Applications for Actual Use">
                                <NavLink
                                    tag="font"
                                    className={classnames({ active: this.state.activeTab === '2' })}
                                    onClick={() => { this.toggle('2'); }}
                                >
                                    Production
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                    {/* Tab Contents */}
                    <Col>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1" style={{ border: `1px #dee2e6 solid`, borderTop: `none`, padding: `2rem` }} >

                                {this.state.isLoading ? (
                                    <Row>
                                        <div className="text-center" style={{ margin: `auto`, padding: `1.5rem 0` }}>
                                            {`Loading Applications...`}
                                            <BarLoader
                                                sizeUnit={"px"}
                                                width={200}
                                                color={'#123abc'}
                                                css={override}
                                            />
                                        </div>
                                    </Row>

                                ) : (
                                    <Row className="clearfix">
                                        <SandboxAppCard stage="sandbox" data={this.state.data} apiList={this.state.api} />
                                        <br className="clearfix" />
                                        <SandboxAppCard stage="delegated" data={this.state.delegates} apiList={this.state.api} />
                                    </Row>
                                )}
                            </TabPane>
                            <TabPane tabId="2" style={{ border: `1px #dee2e6 solid`, borderTop: `none`, padding: `2rem` }} >
                                <Row style={{ justifyContent: `space-evenly` }}>
                                    {this.state.isLoading ? (
                                        <div className="text-center" style={{ margin: `auto`, padding: `1.5rem 0` }}>
                                            {`Loading Applications...`}
                                            <BarLoader
                                                sizeUnit={"px"}
                                                width={200}
                                                color={'#123abc'}
                                                css={override}
                                            />
                                        </div>
                                    ) : (
                                        <div className="row production-row" style={{ width: `100%` }}>
                                            <ProductionAppCard stage="prod" data={this.state.production} apiList={this.state.api} className="col-md-12" />
                                            <br className="clearfix" />
                                            <RequestAppCard stage="request" data={this.state.req} apiList={this.state.api} className="col-md-12" />
                                        </div>

                                    )}
                                </Row>
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </div>
        )
    }
}


// Sandbox Application View
export const SandboxAppCard = (props) => {
    // API LIST FROM RDS
    const apiList = !props.apiList ? false : props.apiList
    const items = !props.data ? false : (!props.data.Items ? props.data : props.data.Items)

    if (!items || items.length === 0) {
        return (
            <Col md={12} style={{ margin: `auto`, textAlign: `center` }} className="clearfix">
                {props.stage === "sandbox" ? (
                    <p>
                        No Sandbox Applications found. Start creating a new application by clicking on "<b> + New Application</b>"
                    </p>
                ) : (
                    null
                )}
            </Col>
        )
    } else {
        return items.map(function (value, index) {
            return (
                <Col sm={12} md={6} lg={4} xl={3} style={{ marginBottom: `1rem`, padding: `0px 8px` }} key={index}>
                    <Card >
                        <CardBody style={{ padding: `2rem`, minHeight: `280px` }}>
                            <h2 style={{ marginLeft: `unset` }}>
                                <Badge color={props.stage === "sandbox" ? "warning" : props.stage === "close-loop" ? "danger" : props.stage === "delegated" ? "secondary" : "info"}>
                                    {props.stage === "sandbox" ? "Sandbox" : props.stage === "close-loop" ? "Close Loop" : props.stage === "delegated" ? "Delegated" : "Production"}
                                </Badge>
                            </h2>
                            <CardTitle style={{ fontSize: `1.5rem` }}>
                                {value.name.toUpperCase()}
                            </CardTitle>
                            <CardText>
                                {value.api.includes('/GW2/TxnReqListener') || value.api.includes('/GW2/TxnQuery') ? (
                                    <Badge color="success">
                                        eNETS
                                    </Badge>
                                ) : null}
                                {` `}
                                {value.api.includes('/merchantservices/qr/dynamic/v1/order/request') || value.api.includes('/merchantservices/qr/dynamic/v1/transaction/query') || value.api.includes('/merchantservices/qr/dynamic/v1/transaction/reversal') ? (
                                    <Badge color="primary">
                                        NETS QR
                                    </Badge>
                                ) : null}
                                {` `}
                                {value.api.includes('/sit/nof/v1') || value.api.includes('/uat/nof/v2') || value.api.includes('/nof/v2') ? (
                                    <Badge color="info">
                                        NETS Click
                                    </Badge>
                                ) : null}
                            </CardText>
                        </CardBody>
                        <CardFooter
                            className="text-right"
                            style={{
                                paddingLeft: `0rem`,
                                paddingRight: `0rem`
                            }}>
                            <Button
                                color="link"
                                style={{ textDecoration: `none` }}
                                onClick={event => {
                                    event.preventDefault()
                                    navigate('/account/application/view/', { state: { data: items[index], stage: props.stage, apis: apiList } })
                                }}
                            >
                                <b>
                                    View More <MdChevronRight />
                                </b>
                            </Button>
                        </CardFooter>
                    </Card>
                </Col>
            )
        })
    }
}

// Production View
export const ProductionAppCard = (props) => {

    const apiList = !props.apiList ? false : props.apiList
    const items = !props.data ? false : props.data
    if (!items || items.length === 0) {
        return (
            <Col md={12} style={{ margin: `auto`, textAlign: `center` }} className="clearfix">
                No Approved Production Applications found. Start creating a new application by clicking on "<b> + New Application</b>"
            </Col>
        )
    } else {
        return items.map(function (value, index) {
            return (
                <Col sm={12} md={6} lg={4} xl={3} style={{ marginBottom: `1rem`, padding: `0px 8px` }} key={index}>
                    <Card >
                        <CardBody style={{ padding: `2rem`, minHeight: `280px` }}>
                            <h2 style={{ marginLeft: `unset` }}>
                                <Badge color="info">
                                    Production
                                </Badge>
                            </h2>
                            <CardTitle style={{ fontSize: `1.5rem` }}>
                                {!value.name ? 'APPLICATION' : value.name.toUpperCase()}
                            </CardTitle>
                            <CardText>
                                {value.api.includes('cb02e4dd-7cc3-44eb-ba24-65831556c947') || value.api.includes('1a40a709-290e-458a-a033-ebda072e06a6') ? (
                                    <Badge color="success">
                                        eNETS
                                    </Badge>
                                ) : null}
                                {` `}
                                {value.api.includes('35923b45-dd70-4007-85f6-73e269015917') || value.api.includes('73b037f5-591c-4ec6-a701-a7ad93fb5962') || value.api.includes('9c2d1d6d-22ba-42f3-a361-7eb13f6fe076') ? (
                                    <Badge color="primary">
                                        NETS QR
                                    </Badge>
                                ) : null}
                                {value.api.includes('0bb0800b-1f1f-473b-8c8e-99aafb96d796') || value.api.includes('02c0db1a-bb86-4389-aae9-a8185daac785') || value.api.includes('1f0de836-362f-4108-ba76-4bcd44f67a2e') ? (
                                    <Badge color="info">
                                        NETS Click
                                    </Badge>
                                ) : null}
                            </CardText>
                        </CardBody>
                        <CardFooter
                            className="text-right"
                            style={{
                                paddingLeft: `0rem`,
                                paddingRight: `0rem`
                            }}>
                            <Button
                                color="link"
                                style={{ textDecoration: `none` }}
                                onClick={event => {
                                    event.preventDefault()
                                    navigate('/account/application/view/', { state: { data: items[index], stage: "production", apis: apiList } })
                                }}
                            >
                                <b>
                                    View More <MdChevronRight />
                                </b>
                            </Button>
                        </CardFooter>
                    </Card>
                </Col>
            )
        })
    }
}

// Request View
export const RequestAppCard = (props) => {
    const items = !props.data ? false : props.data

    let badge = null

    // for(let x = 0; x < props.apiList.length; x++) {

    // }

    // console.log("REQUEST APP")
    // console.log(items)

    if (!items || items.length === 0) {
        return (
            <Col md={12} style={{ margin: `auto`, textAlign: `center` }} className="clearfix">
                <hr />
            </Col>
        )
    } else {
        return items.map(function (value, index) {
            // if (value.api.constructor !== Array) {
            //     let parsed = JSON.parse(value.api)
            //     value.apiParsed = parsed ? parsed : {}
            // }
            // console.log("REQUEST APP")
            // console.log(value)
            return (
                <Col sm={12} md={6} lg={4} xl={3} style={{ marginBottom: `1rem`, padding: `0px 8px` }} key={index}>
                    <Card >
                        <CardBody style={{ padding: `2rem`, minHeight: `280px` }}>
                            <h2 style={{ marginLeft: `unset` }}>
                                <Badge color="secondary">
                                    UNDER REVIEW
                                </Badge>
                            </h2>
                            <CardTitle style={{ fontSize: `1.5rem` }}>
                                {!value.app_name ? 'APPLICATION' : value.app_name.toUpperCase()}
                            </CardTitle>
                            <CardText>
                                {value.api.includes('cb02e4dd-7cc3-44eb-ba24-65831556c947') || value.api.includes('1a40a709-290e-458a-a033-ebda072e06a6') ? (
                                    <Badge color="success">
                                        eNETS
                                    </Badge>
                                ) : null}
                                {` `}
                                {value.api.includes('35923b45-dd70-4007-85f6-73e269015917') || value.api.includes('73b037f5-591c-4ec6-a701-a7ad93fb5962') || value.api.includes('9c2d1d6d-22ba-42f3-a361-7eb13f6fe076') ? (
                                    <Badge color="primary">
                                        NETS QR
                                    </Badge>
                                ) : null}
                                  {value.api.includes('0bb0800b-1f1f-473b-8c8e-99aafb96d796') || value.api.includes('02c0db1a-bb86-4389-aae9-a8185daac785') || value.api.includes('1f0de836-362f-4108-ba76-4bcd44f67a2e') ? (
                                    <Badge color="info">
                                        NETS Click
                                    </Badge>
                                ) : null}
                            </CardText>
                        </CardBody>
                        <CardFooter
                            className="text-right"
                            style={{
                                paddingLeft: `0rem`,
                                paddingRight: `0rem`
                            }}>
                            <Button
                                color="link"
                                style={{ textDecoration: `none`, color: `#fff !important`, pointerEvents: `none` }}
                                onClick={event => {
                                    event.preventDefault()
                                }}
                            >
                                <b >
                                    Request Date : {moment(value.requestdate).format('DD MMMM YYYY')}
                                </b>
                            </Button>

                        </CardFooter>
                    </Card>
                </Col>
            )
        })
    }
}

export const ApplicationLayout = props => {
    return (
        <Layout>
            <Banner title={`Application`} />
            {props.location ? (<Breadcrumb loc={props.location} />) : undefined}
            <Container
                fluid
                className="application-container"
            >
                {props.children}
            </Container>
        </Layout>
    )
}